import { Subscribe } from '@react-rxjs/core'
import React, { useEffect, useMemo } from 'react'

import { SanityParentProductFragment } from '../../../../graphql/gatsby'
import { useResultWithLeftEffect } from '../../../../hooks/useResultWithLeftEffect'
import {
  latestProductsBySkus$,
  searchProductsBySkus,
  useProductsBySkus,
} from '../../../../lib/products/products-by-skus'
import { constNull } from '../../../../utils/constant'
import { pluckData } from '../../../../utils/result'
import BannerProductCard from '../../../products/banners/cards/ProductBannerCard'
import Spinner from '../../../Spinner'
import * as Styled from './styled'

export interface RelatedProductsProps {
  relatedProducts: {
    title: SanityParentProductFragment['relatedProductsTitle']
    subtitle: SanityParentProductFragment['relatedProductsSubtitle']
    products: NonNullable<SanityParentProductFragment['relatedProducts']>
  }
}

export type RelatedProductsContentProps = RelatedProductsProps & {
  skus: string[]
}

export const RelatedProductsContent: React.FC<RelatedProductsContentProps> = ({
  skus,
  relatedProducts,
}) => {
  const [, data] = useResultWithLeftEffect(useProductsBySkus(skus), pluckData, constNull)
  const products = data?.products ?? []

  const productsDetails = useMemo(
    () =>
      relatedProducts.products
        .map((product) => {
          return {
            sku: product?.sku || '',
            tagline: product?.tagline || '',
            shortDescription: product?.shortDescription || '',
          }
        })
        .filter(Boolean) || [],
    [relatedProducts]
  )

  if (products.length === 0) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.Title>{relatedProducts.title ?? 'Related Products'}</Styled.Title>
      {relatedProducts.subtitle && <Styled.Subtitle>{relatedProducts.subtitle}</Styled.Subtitle>}
      <Styled.ScrollableContainer>
        {products.map((relatedProduct, index) => (
          <BannerProductCard
            key={relatedProduct.sku}
            product={relatedProduct}
            productDetails={productsDetails[index]}
            analyticsEventAppContext="related_products"
          />
        ))}
      </Styled.ScrollableContainer>
    </Styled.Container>
  )
}

export const RelatedProducts: React.FC<RelatedProductsProps> = ({ relatedProducts }) => {
  const skus = useMemo(
    () => relatedProducts.products.map((product) => product?.sku || '').filter(Boolean) || [],
    [relatedProducts]
  )

  useEffect(() => {
    if (skus.length > 0) {
      searchProductsBySkus(skus)
    }
  }, [skus])

  if (skus.length === 0) {
    return null
  }
  return (
    <Subscribe source$={latestProductsBySkus$(skus)} fallback={<Spinner loading />}>
      <RelatedProductsContent skus={skus} relatedProducts={relatedProducts} />
    </Subscribe>
  )
}
