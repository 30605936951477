import React, { FC } from 'react'
import styled from 'styled-components'

import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../../graphql/magento'
import { displayAttribute } from '../../../../utils/itemTools'
import { ItemDetailsRenderCleanHtml } from './ItemDetailsRenderCleanHtml'

export interface ItemDescriptionProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  selectedVariant?: SimpleProductDetailsFragment | null | undefined
}

const ItemDescription: FC<ItemDescriptionProps> = ({ className, item, selectedVariant = null }) => {
  const htmlString = displayAttribute({
    item: item.description,
    selectedVariant: selectedVariant?.description,
    attribute: 'html',
  })

  if (htmlString) {
    return (
      <section className={className}>
        <h5>DESCRIPTION</h5>
        <ItemDetailsRenderCleanHtml htmlString={htmlString} />
      </section>
    )
  }

  return null
}

const StyledItemDescription = styled(ItemDescription)({
  h5: {
    textTransform: 'uppercase',
  },
})

export default StyledItemDescription
