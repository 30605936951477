import React, { FC } from 'react'
import styled from 'styled-components'

export interface ItemContentBlockProps {
  className?: string
  content: string | null | undefined
}

interface StateObject {
  changeState: (newState: boolean) => void
  getState: () => boolean
}

const loadState = (function (): StateObject {
  let state = false
  const stateObj: Partial<StateObject> = {}
  stateObj.changeState = function (newState: boolean) {
    state = newState
  }
  stateObj.getState = function () {
    return state
  }
  return stateObj as Required<StateObject>
})()

// TODO: validate if this component is being used/rendered at all, and refactor `handleClick`
const ItemContentBlock: FC<ItemContentBlockProps> = ({ className = '', content = null }) => {
  function handleClick() {
    if (loadState.getState()) {
      return
    }
    const accLiTitle = document.getElementsByClassName('accordion-list-title')
    const plusCross = document.querySelectorAll('[data-effect="plus-cross"]')

    if (accLiTitle.length) {
      // TODO: `Object.entries` shouldn't be used on `HTMLCollectionOf`, and due to this TS infers `accKey` as string
      for (const [accKey] of (Object.entries(accLiTitle) as unknown) as [number, Element][]) {
        const parentKey = accKey
        const target = accLiTitle[accKey].getAttribute('data-target')?.substring(1)
        const targetContent = document.getElementsByClassName(target as string)[0]
        accLiTitle[accKey].addEventListener('click', () => {
          targetContent.classList.toggle('in')
          if (plusCross.length) {
            // TODO: `Object.entries` shouldn't be used on `NodeListOf`, and due to this TS infers `crossKey` as string
            for (const [crossKey] of (Object.entries(plusCross) as unknown) as [
              number,
              Element
            ][]) {
              if (plusCross[crossKey].parentNode === accLiTitle[parentKey]) {
                plusCross[crossKey].classList.toggle('open')
              }
            }
          }
        })
      }
      loadState.changeState(true)
    }
  }

  if (content) {
    return (
      // TODO: this probably should be passing `handleClick` instead of calling it
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <section className={className} onLoad={handleClick()}>
        <div
          // TODO: don't use `dangerouslySetInnerHTML` if possible
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </section>
    )
  }

  return null
}

const StyledItemContentBlock = styled(ItemContentBlock)(({ theme }) => ({
  // These classes are being used to emulate the bootstrap
  // classes that are automatically being set via NetSuite
  '.accordion-list-item:first-child': {
    marginTop: '0',
    borderTop: '0',
  },
  '.accordion-list-item': {
    borderRight: '0',
    borderLeft: '0',
    marginTop: '-1px',
  },
  '.accordion-list-item:first-child .accordion-list-title': {
    paddingTop: '0',
  },
  '.accordion-list-title': {
    padding: '15px 0',
    cursor: 'pointer',
  },
  '.title-extra-small': {
    fontFamily: theme.fontFamilies.gotham,
    fontSize: '18px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '1.2',
  },
  '.plus-cross': {
    position: 'relative',
    width: '20px',
    height: '20px',
    margin: '0 10px 10px',
  },
  '.accordion-list-icon': {
    float: 'right',
  },
  '.open .line-1': {
    transform: 'rotate(-45deg) translate(0)',
  },
  '.line-1': {
    transform: 'rotate(90deg) translate(-0.5px)',
  },
  '.line': {
    height: '2px',
    background: '#051120',
    width: '20px',
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    right: '0',
    left: '0',
    transition: '.3s all',
  },
  '.open .line-2': {
    transform: 'rotate(45deg)',
  },
  '.collapse.in': {
    display: 'block',
    visibility: 'visible',
  },
  '.accordion-list-content': {
    padding: '0 0 15px',
  },
  '.collapse': {
    display: 'none',
    visibility: 'hidden',
  },
  '.copy-size-3': {
    fontFamily: theme.fontFamilies.gotham,
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '21px',
  },
  a: {
    color: 'inherit',
  },
}))

export default StyledItemContentBlock
