import React, { FC } from 'react'

import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../../graphql/magento'
import { displayAttribute } from '../../../../utils/itemTools'
import { ItemDetailsRenderCleanHtml } from './ItemDetailsRenderCleanHtml'

export interface ItemShortDescriptionProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  selectedVariant: SimpleProductDetailsFragment | null | undefined
}

const ItemShortDescription: FC<ItemShortDescriptionProps> = ({
  className,
  item,
  selectedVariant = null,
}) => (
  <ItemDetailsRenderCleanHtml
    className={className}
    htmlString={displayAttribute({
      item: item.short_description,
      selectedVariant: selectedVariant?.short_description,
      attribute: 'html',
    })}
  />
)

export default ItemShortDescription
