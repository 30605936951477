import { PrimaryButton } from '@designsforhealth/dfh-react-components'
import React, { FC } from 'react'
import styled from 'styled-components'

import { Product } from '../../../../lib/products'
import { ProductDetails } from '../../../../lib/products/modal/types'
import { openQuickViewProductModal } from '../../../../lib/products/quick-view-modal'
import useSanityImage from '../../../../lib/sanity/hooks/useSanityImage'
import Link from '../../../global/Link'

import productImagePlaceholder from '../../../../img/product-image-placeholder.png'

const Separator = styled.span`
  display: block;
  margin: 20px auto;
  border-bottom: 1px solid #ccc;
  width: 90%;
`

const Price = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.brand.blue};
  font-weight: bold;
  margin: 0 0 0 5px;
`

const LinkAndCtaContainer = styled.div`
  margin-top: auto;
`

const BannerAddToCartButton = styled(PrimaryButton)`
  height: 46px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 0;
  border: none;
  border-radius: 8px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 1200px) {
    font-size: 1rem;
  }
`

export interface ProductBannerCardProps {
  className?: string
  product: Product
  productDetails?: ProductDetails
  analyticsEventAppContext?: 'plp' | 'related_products' | 'featured_products'
}

const ProductBannerCard: FC<ProductBannerCardProps> = ({
  className,
  product,
  productDetails,
  analyticsEventAppContext,
}) => {
  const sanityImageUrl = useSanityImage(
    { sourceJson: product.sanityImageJson || undefined },
    {
      dpr: 2,
      fit: 'max',
      // set width only to avoid crop bug: https://github.com/sanity-io/image-url/issues/32
      width: 175,
    }
  )
  const productUrl = sanityImageUrl || productImagePlaceholder

  return (
    <div className={className}>
      <div className="product-image">
        <Link to={`/products/${product.urlKey}/`}>
          <img alt={product.name || undefined} src={productUrl} />
        </Link>
      </div>
      <Separator />
      <div className="product-details">
        <Link to={`/products/${product.urlKey}/`}>
          <h4>{product.name}</h4>
        </Link>
        <LinkAndCtaContainer>
          {product.priceRange?.minimum && (
            <p className="small-paragraph small-margin-bottom">
              {product.priceRange?.minimum !== product.priceRange?.maximum ? `from ` : null}{' '}
              <Price>${product.priceRange?.minimum}</Price>
            </p>
          )}
          <BannerAddToCartButton
            onClick={() =>
              openQuickViewProductModal({
                sku: product.sku,
                urlKey: product.urlKey,
                productDetails,
                analyticsEventAppContext,
              })
            }
          >
            Add to Cart
          </BannerAddToCartButton>
        </LinkAndCtaContainer>
      </div>
    </div>
  )
}

ProductBannerCard.defaultProps = {
  className: '',
}

const StyledProductBannerCard = styled(ProductBannerCard)(({ theme }) => ({
  backgroundColor: theme.colors.grayscale.white,
  border: `1px solid ${theme.colors.coolGray.cool150}`,
  borderRadius: '5px',
  display: 'flex',
  flex: '1 0 100%',
  minHeight: '382px',
  hyphens: 'auto',
  justifyContent: 'flex-start',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  boxSizing: 'border-box',
  flexDirection: 'column',
  paddingTop: '20px',
  a: {
    color: theme.colors.grayscale.black,
    textDecoration: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  h4: {
    color: '#2152ac',
    padding: '0 15px 8px 0',
    '@media(min-width: 1440px)': {
      fontSize: '18px',
    },
  },
  img: {
    height: '175px',
    width: '175px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  '.product-image': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    img: {
      alignSelf: 'center',
    },
    a: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  },
  '.product-details': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    padding: '0 15px 15px',
    marginBottom: '0',
    '.small-paragraph.small-margin-bottom': {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      '@media(min-width: 1440px)': {
        fontSize: '14px',
      },
    },
  },
  '.tagline': {
    textTransform: 'uppercase',
  },
  '@media(min-width: 600px)': {
    flex: '0 0 calc(50%/6)',
    maxWidth: '260px',
    minWidth: '200px',
  },
  '@media(min-width: 875px)': {
    flex: '0 0 calc(100%/6)',
    width: 'calc(100%/6)',
  },
  '@media(min-width: 1024px)': {
    marginBottom: '20px',
  },
}))

export default StyledProductBannerCard
