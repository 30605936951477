import { bind, SUSPENSE } from '@react-rxjs/core'
import { createKeyedSignal } from '@react-rxjs/utils'
import { map, switchMap } from 'rxjs/operators'

import { mapRightSuspended } from '../../utils/rx/operators'
import { searchExactQuery } from '../search/exact-query'

const keyForSkus = (skus: string[]) => JSON.stringify(skus.slice().sort())

const [searchProductsBySkus$, searchProductsBySkus] = createKeyedSignal(keyForSkus)

export { searchProductsBySkus }

const productsBySkus$ = (skus: string[]) =>
  searchProductsBySkus$(keyForSkus(skus)).pipe(
    switchMap(() =>
      searchExactQuery({ skus }).pipe(
        mapRightSuspended((value) => ({
          _tag: 'Right' as const,
          data: { products: value.data },
        })),
        map((value) =>
          value === SUSPENSE
            ? {
                _tag: 'Right' as const,
                data: { products: [] },
              }
            : value
        )
      )
    )
  )

export const [useProductsBySkus, latestProductsBySkus$] = bind(productsBySkus$)
