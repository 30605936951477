import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import { graphql, PageProps } from 'gatsby'
import React, { FC } from 'react'

import { ProductPageTemplateQuery } from '../../../graphql/gatsby'
import Head from '../../../layouts/Head'
import { ProductPageContext } from '../../../lib/pages'
import { isSanityImageObject } from '../../../lib/sanity'
import Layout from '../../global/Layout'
import ItemContainer from './ItemContainer'

export type ProductPageProps = Pick<
  PageProps<ProductPageTemplateQuery, ProductPageContext>,
  'data' | 'pageContext'
>

export const ProductPage: FC<ProductPageProps> = ({ data, pageContext: { urlKey } }) => {
  const product = data.sanityParentProduct
  return (
    <>
      {product ? (
        <Head
          title={product.title || 'Product'}
          description={product.metaDescription}
          keywords={product.keywords}
          image={
            isSanityImageObject(product.openGraphImage)
              ? product.openGraphImage
              : isSanityImageObject(product.image)
              ? product.image
              : undefined
          }
        />
      ) : (
        <Head title="Product" />
      )}
      <GlobalStyle includeTypography />
      <Layout>
        <ItemContainer pageContext={{ urlKey }} sanityParentProduct={product} />
      </Layout>
    </>
  )
}

export const sanityParentProductFragment = graphql`
  fragment sanityParentProduct on SanityParentProduct {
    metaDescription
    keywords
    shortDescription
    sku
    tagline
    title
    brand
    image {
      ...SanityImageObject
    }
    openGraphImage {
      ...SanityImageObject
    }
    relatedProductsTitle
    relatedProductsSubtitle
    relatedProducts {
      sku
      tagline
      shortDescription
    }
    products {
      ... on SanityProduct {
        sku
        prices {
          retail {
            amount
            currency
          }
        }
        mainImage {
          alt
          imageUrl: asset {
            url
          }
        }
        otherImages {
          ... on SanityProductImage {
            alt
            imageUrl: asset {
              url
            }
          }
        }
      }
    }
  }
`
