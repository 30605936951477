import { PageProps } from 'gatsby'
import React from 'react'

import { DispensaryPageAdapter } from '../components/DispensaryPageAdapter'
import { LoadingPage, LoadingPageProps } from '../components/LoadingPage'

/**
 * Higher order component for page templates that inspects the `isDispensary` page context field
 * and wraps the page in a `DispensaryPage` component if it is `true`.
 */
export const withIsDispensaryPageContext = <
  DataType,
  PageContextType extends { isDispensary?: boolean }
>(
  PageToWrap: React.ComponentType<PageProps<DataType, PageContextType>>,
  loadingPageComponent: React.ComponentType<LoadingPageProps> = LoadingPage
): React.FC<PageProps<DataType, PageContextType>> => {
  const WithIsDispensaryPageContext = (props: PageProps<DataType, PageContextType>) => {
    const {
      pageContext: { isDispensary },
    } = props

    return isDispensary ? (
      <DispensaryPageAdapter
        loadingPageComponent={loadingPageComponent}
        page={<PageToWrap {...props} />}
      />
    ) : (
      <PageToWrap {...props} />
    )
  }

  return WithIsDispensaryPageContext
}
