import React, { FC } from 'react'
import styled from 'styled-components'

import {
  ConfigurableProductDetailsFragment,
  SimpleProductDetailsFragment,
} from '../../../graphql/magento'
import { dataSheets } from '../../../utils/itemTools'
import DataSheetLink from './DataSheetLink'

export interface DataSheetLinksProps {
  className?: string
  item: ConfigurableProductDetailsFragment
  selectedVariant: SimpleProductDetailsFragment | null | undefined
}

const DataSheetLinks: FC<DataSheetLinksProps> = ({
  className = '',
  item,
  selectedVariant = null,
}) => (
  <span className={className}>
    {dataSheets({ item, selectedVariant }).map((dataSheet) => (
      <DataSheetLink key={dataSheet.text} {...dataSheet} />
    ))}
  </span>
)

const StyledDataSheetLinks = styled(DataSheetLinks)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: '24px',
})

export default StyledDataSheetLinks
